import Vue from 'vue';
import VueRouter from 'vue-router';
import { DADOS_COMPLETOS_LOCALSTORAGE_KEY, ACCOUNTS_OAUTH_CALLBACK_URL } from '@/consts';
import { getToken } from './services/token';
import { isTargetFromConcursos, isTargetFromSomeAllowedHosts } from './utils/url';

import { getQueryParam } from '@/utils/url';
import { AccountsService } from '@/services/accounts';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => {
    console.warn(err);
  });
};

Vue.use(VueRouter);

const onlyWithoutTokenGuard = (to, from, next) => {
  if (getToken()) next({ name: 'meu-perfil' });
  else next();
};

function getDadosComplementaresQueryParams(route) {
  const _queryParamWithTarget = (updatedTarget) => {
    return { ...route.query, target: updatedTarget, _next: '1' };
  };

  const { target } = route.query;

  // Não precisa adicionar o token no target da URL se estiver usando a lib
  // `auth-client-js`. A lib inclui o param "s=1" pra indicar que não precisa
  // do token na url, visto que ela funciona com o cookie de sessão.
  if (route.query.s === '1') {
    return _queryParamWithTarget(target);
  }

  // Se for concursos, não precisa incluir nada no target, será feito um POST via form submit
  if (isTargetFromConcursos(target)) {
    return _queryParamWithTarget(target);
  }

  // Adiciona token no target da URL se o target for válido e não for o perfil
  const allowedTargetHosts = Vue.env.VUE_APP_ALLOWED_TARGET_HOSTS.split(',');
  const targetIsValid = isTargetFromSomeAllowedHosts(target, allowedTargetHosts);

  if (targetIsValid) {
    const targetUrl = new URL(target);
    const isPerfil = targetUrl.origin === location.origin;

    if (!isPerfil) {
      // TODO: remover quando não tiver mais nenhuma ocorrência de
      // referer com access_token no cloudwatch do Accounts
      targetUrl.searchParams.set('access_token', getToken());
      return _queryParamWithTarget(targetUrl.href);
    }
  }

  return _queryParamWithTarget(location.origin);
}

const routes = [
  // Área logada
  {
    path: '/',
    beforeEnter: async (to, from, next) => {
      const oauthState = getQueryParam('state');
      const token = getToken();

      if (token && oauthState) {
        try {
          const accountService = new AccountsService(Vue.env.VUE_APP_ACCOUNTS_URL, 'perfil', undefined);
          const user = await accountService.getLoggedUserOauthCallbackURL(oauthState);
          localStorage.setItem(ACCOUNTS_OAUTH_CALLBACK_URL, user.oauth_callback_url);
          return next({ name: 'oauth-callback-redirect' });
        } catch (error) {
          console.error('Erro Realizando oauthflow', error);
        }
      }

      if (!token) return next({ name: 'login', query: to.query });

      if (localStorage.getItem(DADOS_COMPLETOS_LOCALSTORAGE_KEY) !== '1') {
        return next({ name: 'dados-complementares', query: getDadosComplementaresQueryParams(to) });
      }

      next();
    },
    component: () => import('./layouts/profile'),
    children: [
      {
        path: '',
        redirect: { name: 'meu-perfil' },
      },
      {
        path: 'meu-perfil',
        meta: { breadcrumb: { label: 'Meu Perfil', to: { name: 'meu-perfil' } } },
        component: () => import('./pages/profile'),
        children: [
          {
            path: '',
            name: 'meu-perfil',
            component: () => import('./pages/profile/meu-perfil'),
          },
          {
            path: 'alterar-perfil',
            name: 'alterar-perfil',
            meta: { breadcrumb: { label: 'Alterar meu perfil' } },
            component: () => import('./pages/profile/meu-perfil/alterar-perfil'),
          },
        ],
      },
      {
        path: 'meus-dados',
        meta: { breadcrumb: { label: 'Meus dados', to: { name: 'meus-dados' } } },
        component: () => import('./pages/profile'),
        children: [
          {
            path: '',
            name: 'meus-dados',
            component: () => import('./pages/profile/meus-dados'),
          },
          {
            path: 'alterar-dados',
            name: 'alterar-dados',
            meta: { breadcrumb: { label: 'Alterar dados' } },
            component: () => import('./pages/profile/meus-dados/alterar-dados'),
          },
          {
            path: 'alterar-endereco',
            name: 'alterar-endereco',
            meta: { breadcrumb: { label: 'Alterar endereço' } },
            component: () => import('./pages/profile/meus-dados/alterar-endereco'),
          },
          {
            path: 'alterar-senha',
            name: 'alterar-senha',
            meta: { breadcrumb: { label: 'Senha' } },
            component: () => import('./pages/profile/meus-dados/alterar-senha'),
          },
        ],
      },
      {
        path: 'minhas-compras',
        meta: { breadcrumb: { label: 'Minhas compras', to: { name: 'minhas-compras' } } },
        component: () => import('./pages/profile'),
        children: [
          {
            path: '',
            name: 'minhas-compras',
            component: () => import('./pages/profile/minhas-compras'),
          },
          {
            path: ':vertical/:orderNumber',
            name: 'pedido-detalhes',
            meta: { breadcrumb: { label: 'Detalhes do pedido' } },
            component: () => import('./pages/profile/minhas-compras/pedido-detalhes'),
          },
        ],
      },
      {
        path: 'dados-pagamento',
        meta: { breadcrumb: { label: 'Dados de pagamento', to: { name: 'dados-pagamento' } } },
        component: () => import('./pages/profile'),
        children: [
          {
            path: '',
            name: 'dados-pagamento',
            component: () => import('./pages/profile/dados-pagamento'),
          },
          {
            path: 'cadastrar-cartao',
            name: 'cadastrar-cartao',
            meta: { breadcrumb: { label: 'Cadastrar novo cartão' } },
            component: () => import('./pages/profile/dados-pagamento/cadastrar-cartao'),
          },
        ],
      },
      {
        path: 'privacidade',
        meta: { breadcrumb: { label: 'Privacidade', to: { name: 'privacidade' } } },
        component: () => import('./pages/profile'),
        children: [
          {
            path: '',
            name: 'privacidade',
            component: () => import('./pages/profile/privacidade'),
          },
        ],
      },
    ],
  },

  // Login / Cadastro
  {
    path: '',
    component: () => import('./layouts/full-screen-centered-card'),
    beforeEnter: (to, from, next) => {
      if (!getToken()) return next();

      return router.push({ name: 'dados-complementares', query: to.query });
    },
    children: [
      {
        path: '/login',
        name: 'login',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/login'),
      },
      {
        path: '/login-confirme-seu-email',
        name: 'login-confirme-seu-email',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/login-confirme-seu-email'),
      },
      {
        path: '/confirmacao-email',
        name: 'confirmacao-email',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/confirmacao-email'),
      },
      {
        path: '/cadastro',
        name: 'cadastro',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/cadastro'),
      },
      {
        path: '/cadastro/preencher-email',
        name: 'cadastro-social-login-sem-email',
        meta: { stepperId: 'identificacao' },
        props: true,
        component: () => import('./pages/cadastro-social-login-sem-email'),
      },
      {
        path: '/cadastro-confirme-seu-email',
        name: 'cadastro-confirme-seu-email',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/cadastro-confirme-seu-email'),
      },
    ],
  },

  // Esqueci minha senha
  {
    path: '',
    component: () => import('./layouts/full-screen-centered-card'),
    beforeEnter: onlyWithoutTokenGuard,
    children: [
      {
        path: '/esqueci-senha',
        name: 'esqueci-senha',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/esqueci-senha'),
      },
      {
        path: '/esqueci-senha-sucesso',
        name: 'esqueci-senha-sucesso',
        meta: { stepperId: 'identificacao' },
        component: () => import('./pages/esqueci-senha-sucesso'),
      },
    ],
  },

  // Dados complementares / Recuperar senha
  {
    path: '',
    component: () => import('./layouts/with-header'),
    children: [
      {
        path: '/dados-complementares',
        name: 'dados-complementares',
        meta: {
          headerTitle: 'Preencha seu cadastro e comece a estudar agora mesmo',
          headerIllustration: require('@/assets/images/register-illustration.svg'),
          stepperId: 'dados',
        },
        beforeEnter: (to, from, next) => {
          if (!getToken()) {
            return next({ name: 'login', query: to.query });
          }

          // Para alterar apenas o query param, é necessário esse workaround (para não ter loop infinito)
          if (to.query._next !== '1') {
            const query = getDadosComplementaresQueryParams(to);

            return router.push({ name: 'dados-complementares', query: query });
          } else {
            return next();
          }
        },
        component: () => import('./pages/dados-complementares'),
      },
      {
        path: '/recuperar-senha',
        name: 'recuperar senha',
        meta: {
          headerTitle: 'Crie uma nova senha para entrar na Área do aluno e no app',
          headerIllustration: require('@/assets/images/header-illustration.svg'),
        },
        component: () => import('./pages/recuperar-senha'),
      },
    ],
  },
  /**
 * Rota para redirecionamento de callback de OAuth.
 * Foi necessário criar essa rota para que o callback de OAuth
 * Pois o window.location não funciona em rotas com o modo history.
 */
  {
    name: 'oauth-callback-redirect',
    path: '/oauth-callback-redirect',
    beforeEnter: async (to, from, next) => {
      const callbackURL = localStorage.getItem(ACCOUNTS_OAUTH_CALLBACK_URL);
      if (callbackURL) {
        localStorage.removeItem(ACCOUNTS_OAUTH_CALLBACK_URL);
        window.location = callbackURL;
        return next();
      }
    },
  },

  // Logout
  {
    path: '/sair',
    name: 'logout',
    component: () => import('./pages/logout'),
  },

  // Catch all
  {
    path: '*',
    redirect: '/',
  },
];

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
