export function getTargetFromUrl() {
  try {
    const target = new URL(location.href).searchParams.get('target');
    return target ? decodeURIComponent(target) : '';
  } catch (e) {
    return '';
  }
}

export function isTargetFromSomeAllowedHosts(target = '', allowedHosts = []) {
  try {
    const targetUrl = new URL(target);
    const targetHost = targetUrl.hostname;
    return allowedHosts.some(host => (host === targetHost) || targetHost.endsWith('.' + host));
  } catch (e) {
    return false;
  }
}

export function isTargetFromConcursos(target = '') {
  const concursosHosts = [
    'estrategiaconcursos.com.br',
  ];
  return isTargetFromSomeAllowedHosts(target, concursosHosts);
}

/**
 * Retorna a query string sem o sinal de interrogação
 */
export function getCurrentQueryString() {
  const queryString = location.search ?? '';
  return queryString.startsWith('?') ? queryString.slice(1) : '';
}

/**
 * Retorna o valor de uma query string
 */
export function getQueryParam (variable) {
  let urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(variable)) {
    return urlParams.get(variable);
  }
  return undefined;
}

/**
 * Retorna a vertical do target
 */
const verticalNames = [
  { subdomain: 'concursos', verticalName: 'concursos' },
  { subdomain: 'militares', verticalName: 'militares' },
  { subdomain: 'vestibulares', verticalName: 'vestibulares' },
  { subdomain: 'carreiras-juridicas', verticalName: 'carreiras-juridicas' },
  { subdomain: 'med', verticalName: 'medicina' },
  { subdomain: 'oab', verticalName: 'oab' },
  { subdomain: 'cj', verticalName: 'carreiras-juridicas' },
];

const defaultVerticalName = verticalNames[0].verticalName;

export function getTargetVerticalName() {
  let target = getTargetFromUrl();

  if (!target) {
    return defaultVerticalName;
  }

  target = target.toLowerCase().trim();
  const found = verticalNames.find(obj => target.includes(obj.subdomain));
  return found ? found.verticalName : defaultVerticalName;
}
